import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="container">
        <h1>Coming Soon</h1>
        <p>We're working on something amazing. Stay tuned!</p>
      </div>
    </div>
  );
}

export default App;